<template>
  <div class="fault-service-center">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="故障业务中心" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="80px" :model="searchData" ref="searchDataRef">
        <el-row>
          <el-col :span="5">
            <!-- 阿米巴 -->
            <ml-select
              prop="companyId"
              placeholder="请选择阿米巴"
              :options="unitOptions"
              label="阿米巴:"
              keyName="companyId"
              labelName="companyName"
              valueName="companyId"
              v-model="searchData.companyId"
              @change="selectUnit"
              :style="{ marginBottom: 0 }"
              clearable
            />
          </el-col>

          <el-col :span="7">
            <!-- 日期 -->
            <ml-date-picker
              prop="date"
              startPlaceholder="开始日期"
              endPlaceholder="结束日期"
              label="操作时间:"
              type="datetimerange"
              v-model="searchData.date"
            />
          </el-col>
          <el-col :span="6">
            <!-- 资产类型 -->
            <ml-select
              prop="propertyType"
              placeholder="请选资产类型"
              :options="propertyTypeList"
              label="资产类型:"
              keyName="label"
              labelName="label"
              valueName="label"
              v-model="searchData.propertyType"
              @change="searchFn"
              clearable
            />
          </el-col>
          <el-col :span="6">
            <!-- 设备类型 -->
            <ml-select
              prop="deviceType"
              placeholder="请选择设备类型"
              :options="deviceTypeList"
              label="设备类型:"
              keyName="label"
              labelName="label"
              valueName="label"
              v-model="searchData.deviceType"
              @change="searchFn"
              clearable
            />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <!-- 所属项目 -->
            <ml-select
              prop="projectId"
              placeholder="请选所属项目"
              :options="projectData"
              label="所属项目:"
              keyName="projectId"
              labelName="projectName"
              valueName="projectId"
              v-model="searchData.projectId"
              :style="{ marginBottom: 0 }"
              @change="searchFn"
              clearable
            />
          </el-col>

          <el-col :span="7">
            <!-- 故障等级 -->
            <ml-select
              prop="severity"
              placeholder="请选择故障等级"
              :options="faultLevelData"
              label="故障等级:"
              labelName="label"
              valueName="label"
              v-model="searchData.severity"
              :style="{ marginBottom: 0 }"
              @change="searchFn"
              clearable
          /></el-col>

          <el-col :span="6">
            <ml-input
              prop="deviceNameLike"
              placeholder="请输入设备名称"
              style="margin-bottom: 0; margin-right: 40px"
              label="设备名称:"
              v-model="searchData.deviceNameLike"
            />
          </el-col>
          <el-col :span="6">
            <!-- 搜索按钮 -->
            <ml-button
              :style="{ 'margin-bottom': 0 }"
              submitText="搜索"
              submitIcon="el-icon-search"
              cancelText="重置"
              cancelIcon="el-icon-refresh"
              cancelType="danger"
              @click-cancel="resetSearchData"
              @click-submit="searchFn"
            />
          </el-col>
        </el-row>
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip class="default-tip" style="margin-bottom: 0; border: 0">
      <!-- 新增按钮 -->
      <template #title>
        <div class="tip-header">
          <el-button-group>
            <el-button
              :type="searchData.searchStatus === '0' ? 'primary' : 'default'"
              @click="clickErrorStatus('0')"
            >
              待处理故障
            </el-button>
            <el-button
              :type="searchData.searchStatus === '1' ? 'primary' : 'default'"
              @click="clickErrorStatus('1')"
            >
              已处理故障
            </el-button>
            <!-- <el-button
                :type="searchData.searchStatus === '2' ? 'primary' : 'default'"
                @click="clickErrorStatus('2')"
              >
                待审核故障
              </el-button> -->
            <el-button
              :type="searchData.searchStatus === '3' ? 'primary' : 'default'"
              @click="clickErrorStatus('3')"
            >
              已办结故障
            </el-button>
          </el-button-group>
          <el-button icon="el-icon-plus" type="primary" @click="clickAdd"> 新增上报 </el-button>
        </div>
      </template>
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(450)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="阿米巴"
          prop="companyName"
          min-width="15%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="所属项目"
          prop="projectName"
          min-width="15%"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="故障上报人" prop="reportUser" min-width="10%">
          <template #default="scope">
            {{ scope.row.reportType === '设备上报' ? 'IOT系统' : scope.row.reportUser }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="事件标题" prop="title" min-width="15%" />
        <el-table-column align="center" label="设备名称" prop="deviceName" min-width="10%" />
        <el-table-column
          align="center"
          label="设备地址"
          prop="address"
          min-width="20%"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="事件时间" prop="eventTime" width="100" />
        <el-table-column align="center" label="故障等级" prop="severity" width="80" />
        <el-table-column align="center" label="申报时间" prop="submitTime" width="100" />
        <el-table-column align="center" label="附件" prop="storageAddress" width="80">
          <template #default="scope">
            <el-button
              v-if="scope.row.attachVos"
              type="text"
              @click="clickAttachVosView(scope.$index, scope.row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="故障状态" prop="showStatusText" width="80">
        </el-table-column>
        <el-table-column align="center" label="操作" priop="partId" width="300">
          <template #default="scope">
            <!-- 0 待处理，1. 处理中，3已完结， -->
            <!-- <el-button
              size="mini"
              icon="el-icon-edit"
              type="warning"
              @click="clickActionReSubmit(scope.$index, scope.row)"
            >
              重新提交
            </el-button> -->
            <el-button
              v-if="scope.row.showStatus === '0'"
              size="mini"
              icon="el-icon-view"
              type="success"
              @click="clickDetails(scope.$index, scope.row)"
            >
              办理
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-view"
              type="success"
              @click="clickDetails(scope.$index, scope.row)"
            >
              查看详情
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
              v-if="isSuperAdmin"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 查看附件提示框 -->
    <ml-dialog
      width="600px"
      customClass="attachVosView-dialog"
      ref="attachVosViewDialogRef"
      title="附件列表"
      :showBtn="false"
      :showClose="true"
    >
      <template #body>
        <ml-list
          fileName="fileName"
          :showDelete="false"
          :showDown="true"
          :list="attachVosViewDialogData.list"
        />
      </template>
    </ml-dialog>

    <!-- 办结提示框 -->
    <ml-dialog
      width="600px"
      ref="errorRecordCompleteDialogRef"
      :title="errorRecordCompleteFormData.title"
      @click-submit="errorRecordCompleteDialogSubmit"
      @click-close="errorRecordCompleteDialogClose"
    >
      <template #body>
        <ml-form
          labelWidth="100px"
          style="width: 95%"
          :model="errorRecordCompleteForm"
          :rules="errorRecordCompleteFormRule"
          ref="errorRecordCompleteFormRef"
        >
          <!-- 办结描述 -->
          <ml-input
            prop="content"
            :placeholder="`请输入${errorRecordCompleteFormData.title}描述`"
            :label="`${errorRecordCompleteFormData.title}描述`"
            type="textarea"
            :rows="5"
            v-model="errorRecordCompleteForm.content"
          />

          <el-form-item>
            <ml-list fileName="fileName" :list="errorRecordCompleteForm.attachVos" />
          </el-form-item>

          <ml-button :showSubmit="false" :showCancel="false">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>
          </ml-button>
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 办结提示框 -->
    <ml-dialog
      ref="deleteErrorDialogRef"
      content="确定删除该故障吗？"
      @click-submit="deleteErrorDialogSubmit"
    ></ml-dialog>
  </div>
</template>
  
  <script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  formetData,
  clearMemoryPageFn,
  getPropertyTypeList,
  setMemoryPageFn,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { BASEURL, FILEUPLOADURL } from '@API'

export default {
  name: 'newFaultServiceCenter',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const routeParams = route.params
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const userInfo = computed(() => getters.getUser)
    const toKen = computed(() => getters.getToken)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    // 是否是超级管理员
    const isSuperAdmin = computed(() => getters.getIsDivisionAdmin)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      companyId: '',
      projectId: '',
      severity: '',
      searchStatus: '0',
      propertyType: '',
      deviceType: '',
      date: [],
      deviceNameLike: ''
    })

    const clickErrorStatus = val => {
      searchData.searchStatus = val
      searchFn()
    }
    // 资产类型
    const propertyTypeList = ref(getPropertyTypeList())
    // 设备类型
    const deviceTypeList = ref([])
    // 监听资产类型变化
    watch(
      () => searchData.propertyType,
      newvalue => {
        deviceTypeList.value = []
        if (newvalue) {
          deviceTypeList.value = propertyTypeList.value.filter(
            item => item.label === newvalue
          )[0].value
        }
      }
    )

    // 单位名下拉列表
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref(getMemoryPage.value.searchData.projectData || [])
    // 故障等级
    const faultLevelData = ref([
      { label: '轻微' },
      { label: '一般' },
      { label: '严重' },
      { label: '灾难' }
    ])

    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    const selectUnit = () => {
      searchData.projectId = ''
      searchFn()
    }
    watch(
      () => searchData.companyId,
      async newvalue => {
        if (newvalue) {
          await getProjectData(newvalue)
          if (routeParams.projectId) {
            searchData.projectId = routeParams.projectId
          }
        }
      }
    )

    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData, { searchStatus: '0', date: [] })
      projectData.value = []
      tabberData.page === 1 ? getFaultServiceCenterTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getFaultServiceCenterTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 获取列表
    const getFaultServiceCenterTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, ['date'], true)
      // if (searchData.searchStatus === '0' && isBusinessDepartment.value) {
      //   searchDataParams.searchStatus = '1'
      // }

      if (searchData['date'] && searchData['date'].length > 0) {
        const [submitTimeStart, submitTimeEnd] = searchData['date']
        searchDataParams.submitTimeStart = formetData(submitTimeStart)
        searchDataParams.submitTimeEnd = formetData(submitTimeEnd)
      }

      searchDataParams.deviceNameLike = replacePerCent(searchData.deviceNameLike)

      const { data } = await getTabberData(
        dispatch,
        'fetchDeviceErrorRecordsPage',
        params,
        searchDataParams
      )
      tabberData.data = data.records || []
      tabberData.total = data.total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getFaultServiceCenterTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 查看附件列表
    const attachVosViewDialogRef = ref()
    const attachVosViewDialogData = reactive({
      list: []
    })
    const clickAttachVosView = (index, row) => {
      attachVosViewDialogData.list = row.attachVos || []
      attachVosViewDialogRef.value.showDialog = true
    }
    // 修改设备
    const clickDetails = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      const { errorId, historyData } = row
      if (historyData == 1) {
        router.push({ path: '/faultServiceCenterDetails', query: { errorId } })
      } else {
        router.push({ path: '/newFaultServiceCenterDetails', query: { errorId } })
      }
    }
    // 重新提交页面
    const clickActionReSubmit = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      const { errorId } = row
      router.push({ path: '/fultServiceCenterActionReSubmit', query: { errorId } })
    }
    // 删除故障
    const deleteErrorDialogRef = ref()
    const deleteErrorDialogData = reactive({
      errorId: ''
    })
    const clickDelete = (index, row) => {
      const { errorId } = row
      deleteErrorDialogData.errorId = errorId
      deleteErrorDialogRef.value.showDialog = true
    }
    const deleteErrorDialogSubmit = () => {
      dispatch('fetchDeleteBatchErrorRecord', [deleteErrorDialogData.errorId]).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          deleteErrorDialogRef.value.showDialog = false
          tabberData.page === 1 ? getFaultServiceCenterTabberData() : (tabberData.page = 1)
        }
      })
    }

    // 添加设备
    const clickAdd = () => {
      clearMemoryPageFn(commit, searchData, { searchStatus: '0' })
      router.push({ path: '/newFaultServiceCenterAction' })
    }

    // 对话框 el
    const errorRecordCompleteDialogRef = ref()
    // 表单 el
    const errorRecordCompleteFormRef = ref()
    const errorRecordCompleteFormData = reactive({
      title: ''
    })
    const errorRecordCompleteForm = reactive({
      content: '',
      attachVos: []
    })

    // 通过，退回按钮
    const clickCheck = (index, row, isComplete) => {
      errorRecordCompleteFormData.errorId = row.errorId
      errorRecordCompleteFormData.title = isComplete ? '通过' : '退回'
      errorRecordCompleteDialogRef.value.showDialog = true
    }
    // 校验
    const errorRecordCompleteFormRule = {
      content: [
        {
          required: true,
          message: '请输入描述',
          trigger: 'blur'
        }
      ]
    }
    // 办洁，退回确认对证框
    const errorRecordCompleteDialogSubmit = () => {
      const isComplete = errorRecordCompleteFormData.title === '通过'
      const actionName = isComplete ? 'fetchCheckOrderAuditRecord' : 'fetchCheckOrderAuditRecord'
      const nParams = searchParams(errorRecordCompleteForm)
      nParams.errorId = errorRecordCompleteFormData.errorId
      if (isComplete) {
        nParams.status = 'Y'
      } else {
        nParams.status = 'N'
      }
      errorRecordCompleteFormRef.value.CustomFormRef.validate()
        .then(() => {
          dispatch(actionName, nParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })

              errorRecordCompleteDialogRef.value.showDialog = false
              tabberData.page === 1 ? getFaultServiceCenterTabberData() : (tabberData.page = 1)
            }
          })
        })
        .catch(() => {})
    }
    // 取消对证框
    const errorRecordCompleteDialogClose = () => {
      errorRecordCompleteForm.attachVos = []
    }

    // 上传附件
    const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    const onSuccess = files => {
      if (files.data) {
        errorRecordCompleteForm.attachVos = [...errorRecordCompleteForm.attachVos, ...files.data]
      }
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []

      if (routeParams.companyId) {
        searchData.companyId = routeParams.companyId
      }
      searchData.companyId = routeParams.companyId || getMemoryPage.value.searchData.companyId || ''
      searchData.projectId = routeParams.projectId || getMemoryPage.value.searchData.projectId || ''
      searchData.severity = getMemoryPage.value.searchData.severity || ''
      searchData.searchStatus = getMemoryPage.value.searchData.searchStatus || '0'
      getProjectData('')
      setTimeout(() => {
        getFaultServiceCenterTabberData()
      }, 600)
    })

    console.log(searchData.date)
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickDetails,
      clickAdd,
      unitOptions,
      projectData,
      propertyTypeList,
      deviceTypeList,
      faultLevelData,
      selectUnit,
      clickErrorStatus,
      formetData,
      attachVosViewDialogRef,
      attachVosViewDialogData,
      clickAttachVosView,
      clickActionReSubmit,
      userInfo,
      clickCheck,
      errorRecordCompleteDialogRef,
      errorRecordCompleteFormRef,
      errorRecordCompleteFormData,
      errorRecordCompleteForm,
      errorRecordCompleteFormRule,
      errorRecordCompleteDialogSubmit,
      errorRecordCompleteDialogClose,
      action,
      onSuccess,
      toKen,
      clickDelete,
      deleteErrorDialogRef,
      deleteErrorDialogSubmit,
      isSuperAdmin
    }
  }
}
</script>
  
  <style lang="scss">
.fault-service-center {
  @extend %params-global;
}
.attachVosView-dialog {
  .el-dialog__body {
    justify-content: start;
  }
}
</style>
  